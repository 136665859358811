import { BrowserRouter } from "react-router-dom";
import AboutUs from "./pages/AboutUs";
import NavBar from "./components/navbar/Navbar";
import Footer from "./pages/Footer";
import Partners from "./pages/Partners";
import Fields from "./pages/Fields";


function App() {
  return (
    <>
      <BrowserRouter>
        <NavBar />
        <AboutUs />
        <Fields />
        <Partners />
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
