import { Button, Container } from "react-bootstrap";
import { motion } from "framer-motion";
import AnimationTitles from "../components/functions/AnimationTitles";

function AboutUs() {
  return (
    <div className="about">
      <Container className="d-flex justify-content-between flex-wrap flex-md-nowrap">
        <motion.div
          initial={{ x: -200 }}
          whileInView={{ x: 0 }}
          transition={{ duration: 0.8 }}
        >
          <AnimationTitles title="Welcome to OLYM3 Blockchain!" className="title" />
          <p className="white-100 mb-5">
            OLYM3 is a pioneering company connecting the career training community with the web3 blockchain, focusing on EduFi, DeFi, GameFi (Unity), and DePin. It offers in-depth courses and high-quality training programs, providing advanced knowledge in these areas. OLYM3's online platform enables learners to connect with experts, employers, and like-minded communities.
          </p>
          <p className="white-100 mb-5">  
            By utilizing blockchain technology, the company ensures transparency, security, and the tracking of individual learning progress. OLYM3 not only provides educational opportunities but also expands career prospects in the global digital market, fostering an innovative and sustainable community..
          </p>
          <Button variant="primary ms-0">Read More</Button>
        </motion.div>
        <motion.div
          initial={{ x: 200 }}
          whileInView={{ x: 0 }}
          transition={{ duration: 0.8 }}
          className="d-flex flex-column"
        >
          <div className="d-flex">
            <div>
              <img
                src={require("../images/fields/edufi.jpeg")}
                className="p-0 me-2 img"
                alt="img"
              />
            </div>
            <div>
              <img
                src={require("../images/fields/defi.jpeg")}
                className="p-0 img"
                alt="img"
              />
            </div>
          </div>
          <div className="d-flex">
            <div>
              <img
                src={require("../images/fields/gamefi.jpeg")}
                className="p-0 me-2 img"
                alt="img"
              />
            </div>
            <div>
              <img
                src={require("../images/fields/depin.jpeg")}
                className="p-0 img"
                alt="img"
              />
            </div>
          </div>
        </motion.div>
      </Container>
    </div>
  );
}

export default AboutUs;