// Import bootstrap react components
import { Button, Card, Container, Row, Col } from "react-bootstrap";
// Import framer motion
import { motion } from "framer-motion";
import AnimationTitles from "../components/functions/AnimationTitles";
import React from 'react';

function OFields() {
  // Toggle the like button state
  function like(e) {
    e.target.classList.toggle("fa-solid");
    e.target.classList.toggle("fa-regular");
    e.target.classList.toggle("text-danger");
  }

  // Function to handle viewing fields
  function viewFields(ofield) {
    let url = '';
    switch (ofield.title) {
      case 'DeFi':
        url = 'https://defi.vn/';
        break;
      case 'DePin':
        url = 'https://dorahacks.io/buidl/15997';
        break;
      case 'EduFi':
        url = 'https://www.facebook.com/bachkhoaalumni';
        break;
      case 'GameFi':
        url = 'https://about.maithanhnha.com';
        break;
      default:
        alert(`No URL found for ${ofield.title}`);
        return;
    }
    window.open(url, '_blank');
  }

  // Card data
  const cardData = [
    {
      imgSrc: require("../images/fields/edufi.jpeg"),
      title: "EduFi",
      agency: "Education Finance refers to the management, allocation, and distribution of financial resources to support educational systems, institutions, and students...",
    },
    {
      imgSrc: require("../images/fields/defi.jpeg"),
      title: "DeFi",
      agency: "Decentralized Finance (DeFi) refers to a financial system that operates on blockchain technology, removing the need for traditional intermediaries...",
    },
    {
      imgSrc: require("../images/fields/gamefi.jpeg"),
      title: "GameFi",
      agency: "Game Finance (GameFi) refers to create a system where players can earn financial rewards, such as cryptocurrencies or NFTs...",
    },
    {
      imgSrc: require("../images/fields/depin.jpeg"),
      title: "DePin",
      agency: "DePIN (Decentralized Physical Infrastructure Networks) refers to a decentralized system that leverages blockchain technology...",
    },
  ];

  return (
    // Start fields
    <div className="fields">
      <Container>
        <AnimationTitles
          className="title mx-auto"
          title="Main Fields"
        />
        {/* Start tabs */}
        <div className="tabs d-flex justify-content-start justify-content-sm-center align-items-center flex-nowrap w-lg-50">
          {/* Placeholder for Swiper or tabs content */}
        </div>
        {/* End tabs */}
        {/* Start cards */}
        <motion.div
          initial={{ x: -80 }}
          whileInView={{ x: 0 }}
          transition={{ duration: 0.8 }}
        >
          <div className="d-flex flex-wrap gap-3 mt-4">
            {cardData.map((card, index) => {
              return (
                <div key={index} className="d-flex flex-column" style={{ flexBasis: 'calc(50% - 15px)', maxWidth: 'calc(50% - 15px)' }}>
                  <Card className="bg-black-100 rounded">
                    <div className="position-relative">
                      <Card.Img
                        variant="top"
                        alt="img"
                        src={card.imgSrc}
                        className="card-img"
                        onClick={() => viewFields(card)} // Add onClick to image
                        style={{ cursor: 'pointer' }} // Change cursor to pointer
                      />
                      <i className="fa-regular fa-heart like" onClick={like}></i>
                    </div>
                    <Card.Body className="p-2">
                      <h5 className="mt-2 text-white fw-normal">{card.title}</h5>
                      <p className="gray-90">{card.agency}</p>
                      <Row className="mt-2">
                        {/* Column 1: Reserve */}
                        <Col xs={12} md={4} className="d-flex flex-column justify-content-between">
                        </Col>

                        {/* Column 2: Reserve */}
                        <Col xs={12} md={4} className="d-flex flex-column align-items-center">
                        </Col>

                        {/* Column 3: Explore Button */}
                        <Col xs={12} md={4} className="d-flex flex-column align-items-center">
                          <Button 
                            className="btn-buy-eth mt-2" 
                            variant="primary"
                            onClick={() => viewFields(card)} // Add onClick handler
                          >
                            VIEW
                          </Button>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </div>
              );
            })}
          </div>
        </motion.div>
        {/* End cards */}
      </Container>
    </div>
    // End fields
  );
}

export default OFields;
