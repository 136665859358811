import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import logo from "../../images/logo/logo.png";
import { Button, Modal } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./navbar.css";

function NavBar() {
  const [showPopup, setShowPopup] = useState(false);
  const [popupContent, setPopupContent] = useState("");

  const handleShowPopup = (content) => {
    setPopupContent(content);
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <>
      <Navbar expand="lg" className="py-3">
        <Container>
          <Navbar.Brand href="#" className="me-lg-5">
            <img className="logo" src={logo} alt="logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav className="me-auto my-2 my-lg-0" navbarScroll>
              <Nav.Link
                href="#edufi"
                onClick={() =>
                  handleShowPopup(
                    "Education Finance refers to the management, allocation, and distribution of financial resources to support educational systems, institutions, and students."
                  )
                }
              >
                EduFi
              </Nav.Link>
              <Nav.Link href="#defi" className="px-lg-3">
                DeFi
              </Nav.Link>
              <Nav.Link href="#gamefi">GameFi</Nav.Link>
              <Nav.Link
                href="#depin"
                onClick={() =>
                  handleShowPopup(
                    "DePIN (Decentralized Physical Infrastructure Networks) refers to a decentralized system that leverages blockchain technology."
                  )
                }
              >
                DePin
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
          <div className="d-flex align-items-center order">
            <a
              href="https://www.olym3labs.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button
                variant="primary"
                className="btn-primary d-none d-lg-inline-block"
              >
                Launch DApp
              </Button>
            </a>
          </div>
        </Container>
      </Navbar>

      {/* Popup Modal */}
      <Modal show={showPopup} onHide={handleClosePopup}>
        <Modal.Header closeButton>
          <Modal.Title>Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>{popupContent}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClosePopup}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default NavBar;
